.circle {
  &__box {
    width: 100px;
    height: 100px;
    position: relative;
  }
  &__wrapper {
    width: 50px;
    height: 100px;
    position: absolute;
    top: 0;
    overflow: hidden;
    &--right {
      right: 0;
    }
    &--left {
      left: 0;
    }
  }
  &__whole {
    width: 80px;
    height: 80px;
    border: 10px solid transparent;
    border-radius: 50%;
    position: absolute;
    top: 0;
    transform: rotate(-135deg);
  }
  &__right {
    border-top: 10px solid #3e6fab;
    border-right: 10px solid #3e6fab;
    right: 11px;
    animation: circleRight 0.5s linear forwards;
    &--no-animate {
      animation-duration: 0s;
    }
  }
  &__left {
    border-bottom: 10px solid #3e6fab;
    border-left: 10px solid #3e6fab;
    left: 11px;
    animation: circleLeft 0.5s linear forwards;
    &--no-animate {
      animation-duration: 0s;
    }
  }
}

@keyframes circleRight {
  0% {
    transform: rotate(-135deg);
  }
  50%,
  100% {
    transform: rotate(45deg);
  }
}

@keyframes circleLeft {
  0%,
  50% {
    transform: rotate(-135deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}
