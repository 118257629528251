@keyframes customEnterModalAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    right: 0;
  }
  100% {
    right: -600px;
  }
}
